import * as Styled from "./style";
import Button from "../Button/button";
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import HttpApis from "../../../services/HttpApis";

const Signature = ({ setSign = () => null, userDetail, fetchLicensesList }) => {
  const signatureRef = useRef();
  
  const handleClear = () => {
    localStorage.setItem("sign", false);
    signatureRef?.current?.clear();
  };

  const handleImage = () => {
    localStorage.setItem("sign", true);
    setSign(signatureRef?.current?.toDataURL());
  };

  const handleSubmit = async () => {
    const data = signatureRef?.current?.toDataURL();
    setSign(data);
    if (
      userDetail?.type === "Finance Account" ||
      userDetail?.type === "Operations"
    ) {
      const response = await HttpApis.licensesSignature({ sign: data });
      if (response.status === 200) {
        fetchLicensesList();
      }
      return response;
    }
  };

  return (
    <Styled.Signature>
      <div className="canvas">
        <SignatureCanvas
          penColor="blue"
          canvasProps={{ width: 200, height: 50 }}
          ref={(eleRef) => (signatureRef.current = eleRef)}
          onEnd={handleImage}
        />
      </div>
      <div className="butn">
        {userDetail?.type === "Finance Account" ||
        userDetail?.type === "Operations" ? (
          <Button title={"Save"} onClick={handleSubmit} className="btn" />
        ) : null}
        <Button title={"Clear"} onClick={handleClear} className="btn" />
      </div>
    </Styled.Signature>
  );
};
export default Signature;
