import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import RatingModal from "./RatingModal";
import React from "react";
import eng from "../../../assets/Svg/eng.svg";
import gsatimg from "../../../assets/Svg/gsatimg.svg";
import tv from "../../../assets/Svg/tv.svg";
import { course, rating } from "./data";

const TrainingAwareness = () => {
  return (
    <>
      <Styled.Courses className="container">
        <div className="about">{course.about}</div>
        <Styled.Gsat>
          <div>
            <img src={gsatimg} alt="gsat" />
          </div>
          <div className="security">
            <span className="general"> {course.general}</span>
            {course.security}
          </div>
        </Styled.Gsat>
        <Styled.Rating>
          <div className="eng">
            <img src={tv} />
            <div>English</div>
          </div>
          <div className="hrs">
            {" "}
            <img src={eng} />
            <div> 1,5 Hours</div>
          </div>
          <div className="ratings">
            Rating
            <div>
              {rating?.map((item, index) => {
                return <img key={index} src={item?.rate} />;
              })}
            </div>
            <span className="rate">1.2k</span>
          </div>
        </Styled.Rating>
        <div className="asessVali">
          <div className="online">
            <span className="assess"> {course.assess}</span>
            {course.online}
          </div>
          <div className="vali">{course.validate}</div>
        </div>
        <Styled.Line />
        <Styled.Certify>
          <div className="cert">{course.certify}</div>
          <div className="grey">
            <div className="comp">{course.comp}</div>
          </div>
        </Styled.Certify>

        <Styled.Line />
        <Styled.Review>
          <Styled.Cart>
            <div className="cert">{course.review}</div>
            <Styled.FilterRate>
              <div className="filterRate">{course.filterRate}</div>
              <div className="optionValue">
                <select id="selectOption">
                  <option value="option1">All ratings</option>
                </select>
              </div>
            </Styled.FilterRate>
          </Styled.Cart>
          <Styled.LeaveRating>
            <div className="rating">
              <div className="rateIndex">
                <div>
                  {rating?.map((item, index) => {
                    return <img key={index} src={item?.rate} />;
                  })}
                </div>
                <div className="rates5">
                  {course.rates5}
                  <span className="rates10">{course.ratings10}</span>
                </div>
              </div>
              <div className="btn">
                <RatingModal name={course.cont} />
              </div>
            </div>
            <Styled.Group>
              <div className="jdPage">
                <div className="jd">JD</div>

                <div className="pageJohn">
                  <div className="john">{course.john}</div>
                  <div>
                    {rating?.map((item, index) => {
                      return <img key={index} src={item?.rate} />;
                    })}
                  </div>
                  <div className="page">{course.page}</div>
                  <div className="week">{course.week}</div>
                </div>
              </div>
              <div className="jdPage">
                <div className="jd">MD</div>

                <div className="pageJohn">
                  <div className="john">{course.mary}</div>
                  <div>
                    {rating?.map((item, index) => {
                      return <img key={index} src={item?.rate} />;
                    })}
                  </div>
                  <div className="page">{course.page}</div>
                  <div className="week">{course.week}</div>
                </div>
              </div>
              <div className="jdPage">
                <div className="jd">CD</div>

                <div className="pageJohn">
                  <div className="john">{course.cousin}</div>
                  <div>
                    {rating?.map((item, index) => {
                      return <img key={index} src={item?.rate} />;
                    })}
                  </div>
                  <div className="page">{course.page}</div>
                  <div className="week">{course.week}</div>
                </div>
              </div>
            </Styled.Group>
          </Styled.LeaveRating>
        </Styled.Review>
      </Styled.Courses>
    </>
  );
};

export default TrainingAwareness;
