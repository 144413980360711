import Button from "../../../components/Atoms/Button/button";
import * as Styled from "./style";
import { useState } from "react";
import { IndividualCertificates } from "./IndividualCertificates";
import { CorporateCertificates } from "./CorporateCertificates";
import { CertificateButton } from "./data";

export const CertificateScreen = () => {
  const [toggle, setToggle] = useState(0);
  const handleclick = (id) => {
    setToggle(id);
  };
  return (
    <div>
      <Styled.ButtonContainer>
        {CertificateButton?.map((item) => {
          return (
            <Button
              className={item.id === toggle ? "active" : "non-active"} 
              title={item.title}
              onClick={() => handleclick(item.id)}
            />
          );
        })}
      </Styled.ButtonContainer>

      {toggle === 0 && <IndividualCertificates />}
      {toggle === 1 && <CorporateCertificates />}
    </div>
  );
};
