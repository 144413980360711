import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import React from "react";
import { Input, Tag } from "antd";
import { BiFilterAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { FaRegFileExcel } from "react-icons/fa";
import { LuListFilter } from "react-icons/lu";
import { useIndividualCertificatesHook } from "../../../hooks/IndividualCertificatesHook";
import { dataItem } from "./data";

export const IndividualCertificates = () => {
  const { TableCourse, tableData } = useIndividualCertificatesHook();
  return (
    <div>
      <Styled.CompletedCourses>
        <span className="courses">{dataItem.Completed}</span>
        <Styled.Pipe />
        <span className="number">{dataItem.Number}</span>
      </Styled.CompletedCourses>
      <Styled.BelowText>{dataItem.BelowText}</Styled.BelowText>
      <Styled.MainContainer2>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.Searchbar>
              <Input
                className="search"
                size="large"
                placeholder={dataItem.invoice}
                prefix={<CgSearch className="searchicon" />}
              />
            </Styled.Searchbar>

            <Styled.Dropdown>
              <BiFilterAlt className="filter" />
              <Styled.DropData>
                <select name="language" id="language" className="gsat">
                  <option value="GSAT" selected>
                    {dataItem.GSAT}
                  </option>
                </select>
              </Styled.DropData>
            </Styled.Dropdown>

            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select name="language" id="language" className="newest">
                  <option value="GSAT" selected>
                    {dataItem.Newestfirst}
                  </option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
            <Styled.Spreadsheet>
              <FaRegFileExcel />
              <span>{dataItem.DownloadSpreadsheet}</span>
            </Styled.Spreadsheet>
          </div>

          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <div>
          <AntdTable data={tableData} columns={TableCourse} />
        </div>
      </Styled.MainContainer2>
    </div>
  );
};
