export const question = {
  ques: `QUESTION 1.`,
  insider: ` What is the main motive for an insider to pose a threat to civil aviation?`,
  select: `Select ONE:`,
  job: `They no longer like their job`,
  mail: ` Radicalisation and blackmail`,
  found: ` They have found a new job`,
  ques2: `QUESTION 2.`,
  civil: ` Why is Civil Aviation an attractive target for terrorists? `,
  mass: `Likely to cause mass casualties due to crowded areas`,
  duty: ` Goods are cheaper to buy in duty free`,
  rest: ` Security Restricted Areas (SRA) can be easily accessed without identification`,
  ques3: `QUESTION 3.`,
  person: ` A person presents you an expired ID pass and forces entry to SRA. What action should you take?`,
  detain: `Detain and question the person;`,
  build: ` Evacuate the building`,
  imm: ` Report to security immediately`,
  ques4: `QUESTION 4.`,
  sets: ` Which organisation sets legislation on a national level in the UK?`,
  intern: `International Civil Aviation Organisation (ICAO); `,
  depart: `The Department for Transport (DfT);`,
  ex: ` The European Commission (EC).`,
  police: ` The British Transport Police`,
  ques5: `QUESTION 5.`,
  organisation: ` Which organisation is responsible for monitoring compliance with security standards?`,
  center: `Joint Terrorism Analysis Centre (JTAC)`,
  europe: ` The European Commission (EC)`,
  aviat: ` The Civil Aviation Authority (CAA)`,
  interIC: ` The International Civil Aviation Organisation (ICAO)`,
  ques6: `QUESTION 6.`,
  resp: ` Whose responsibility is airport security?`,
  manager: `Managers’ only.`,
  every: ` Everyone’s.`,
  caa: ` The CAA.`,
  staff: ` Security staff.`,
  ques7: `QUESTION 7.`,
  overall: ` What is the overall objective of aviation security?`,
  checkAir: `To check that airports are operating effectively.`,
  ensure: ` To ensure that all passengers are satisfied.`,
  pass: ` To increase passenger volume.`,
  prevents: ` To prevent unlawful interference from happening.`,
  ques8: `QUESTION 8.`,
  conducting: ` What is the reason for conducting a background check before an individual is employed in an aviation security role?`,
  possiblity: `To reduce to possibility of employing an individual who may be a potential threat to the industry`,
  emp: ` To reduce the possibility of employing someone with criminal record for a driving offence`,
  ens: ` To ensure that the individual being employed has completed university degree in aviation security.`,
  ques9: `QUESTION 9.`,
  should: ` Which one of the following checks should be carried out to maintain access control?`,
  attempting: `Check that employees attempting access are wearing the correct uniform`,
  tailgating: ` Allow tailgating through access doors only if you know the employee`,
  valids: ` Challenging any person without visible and valid ID and valid access pass`,
  passport: ` Check that the person attempting access has a passport`,
  ques10: `QUESTION 10.`,
  discover: ` What should you do if you discover an unattended bag within the Security Restricted Area (SRA)?`,
  bags: `Take the bag to security so they can make an announcement and find who it belongs to.`,
  finds: ` Open the bag to see if you can find out who it belongs to.`,
  nearbys: ` Ask people nearby if they own the item and ensure security are alerted.`,
  sra: `  Remove the bag from the Security Restricted Area (SRA) immediately.`,
  ques11: `QUESTION 11.`,
  contribute: ` How do you contribute to aviation security in the workplace?`,
  procedures: `By discussing security procedures with other departments`,
  enters: ` By checking people’s personal belongings before they enter the building`,
  needs: ` By not discussing security related questions with people who do not need to know`,
  socials: ` By spreading awareness of security measures on social media`,
  ques12: `QUESTION 12.`,
  losing: ` What should you do in the event of losing your ID pass?`,
  restricted: `You will still be able to get into security restricted areas as the security staff know that you work there`,
  colleague: ` You can borrow one of your colleague’s ID pass`,
  ids: ` You must inform the ID centre immediately`,
  ques14: `QUESTION 14.`,
  article: ` Which of the below areas is an airside area of an airport?`,
  areaCheck: `Check in area`,
  baggage: ` Baggage drop`,
  duties: ` Duty free retail`,
  ques13: `QUESTION 13. `,
  airside: ` Which one of the following is on the prohibited articles’ list?`,
  aerosols: `Liquids, aerosols and gels`,
  sharp: ` Sharp objects`,
  lights: ` Lighters`,
  keyys: ` Keys`,
  ques15: `QUESTION 15.`,
  landside: ` Which of the below is located in a landside area of an airport?`,
  dutiess: `Duty Free retail shops `,
  runs: `The runway`,
  chck: ` Check in desk`,
  departLon: ` Departure lounges`,
  ques16: `QUESTION 16.`,
  granted: ` Which security checks do you have to go through to be granted access to a Security Restricted Areas (SRA)?`,
  airPass: `Security screening and airport pass`,
  visual: ` Visual check of the pass only`,
  uniforms: ` Uniform check`,
  compBrand: ` Have a company branded pass.`,
  ques17: `QUESTION 17.`,
  escorting: ` What is the main responsibility if escorting a temporary pass holder?`,
  empIssue: `To issue an employment pass`,
  passCarry: ` To monitor the work being carried out`,
  sights: ` To keep the temporary pass holder in their line of sight at all times`,
  ques18: `QUESTION 18.`,
  collectingBelow: ` Which of the below is required when collecting employment pass?`,
  proves: `An employment contract to prove employed status`,
  photographic: ` Government issued photographic ID`,
  completionEv: `Evidence of completion of a background check.`,
  ques19: `QUESTION 19.`,
  onced: ` What should you do with your airport pass once your employment has finished?`,
  dest: `Destroy the pass so it cannot be misused in the future.`,
  transferable: ` Passes are transferable, so you can give it to one of your colleagues.`,
  ret: ` Return it back to the ID centre`,
  works: ` You can keep the pass as the ID centre will stop it from working.`,
  ques20: `QUESTION 20.`,
  whilst: ` Which one of the below must be reported to a manager or a supervisor immediately whilst you are acting as an escort?`,
  escortee:`The escortee is asking a question regarding their job role`,
  seemslike: ` The escortee seems like they already have knowledge of how the pass numbering system works`,
  linedd:` The escortee is trying to leave the group and your line of sight.`,
};
