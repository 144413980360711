import * as Styled from "./style";
import Button from "../../components/Atoms/Button/button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDropdown } from "../../hooks/DropdownHook";
import { logout } from "../../redux/slices/authSlice";

const DropDownHeader = ({ trigger, userData }) => {
  const { btnData } = useDropdown();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [navItems, setItems] = useState(btnData);
  const [open, setOpen] = useState(false);
  const cartList = useSelector((state) => state?.cartReducer?.cart?.data);

  const handleClickItem = (currentItem) => {
    navigate(currentItem.path);
  };

  const logOut = async () => {
    const user = await dispatch(logout());
    localStorage.removeItem("token");
    if (user) {
      navigate("/");
    }
    return user;
  };

  const handleOpen = () => {
    setOpen(!open);
  };
  const ref = useRef();
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };
  useOnClickOutside(
    ref,
    useCallback(() => setOpen(false), [])
  );

  const firstName = userData?.firstName
    ? userData?.firstName?.charAt(0).toUpperCase() +
      userData?.firstName?.slice(1)
    : "";
  const lastName = userData?.lastName
    ? userData?.lastName?.charAt(0).toUpperCase() + userData?.lastName?.slice(1)
    : "";
  return (
    <div ref={ref}>
      <Button title={trigger} onClick={() => handleOpen()} className="JFBtn" />
      {open && (
        <Styled.MainContainer>
          <Styled.Data>
            <Styled.MailData className="container">
              <Styled.IconData>
                <BsFillPersonFill className="iconss" />
                <Styled.Heading>{firstName + " " + lastName}</Styled.Heading>
              </Styled.IconData>
              <Styled.Mail>{userData?.email}</Styled.Mail>
            </Styled.MailData>
            <Styled.UserType>{userData?.type} Account</Styled.UserType>
            <Styled.Line />
            {navItems?.map((items, index) => {
              return (
                <>
                  <Styled.CourserContainer className="container" key={index}>
                    {items?.count ? (
                      <>
                        <Styled.ButtonWrapper
                          active={items.path == location.pathname}
                        >
                          <Button
                            title={items?.title}
                            className="allBtn"
                            onClick={() => handleClickItem(items)}
                          />
                          <span className="count">{cartList?.length}</span>
                        </Styled.ButtonWrapper>
                      </>
                    ) : (
                      <Styled.ButtonWrapper
                        active={items.path == location.pathname}
                      >
                        <Button
                          title={items?.title}
                          className="allBtn"
                          onClick={() => handleClickItem(items)}
                        />
                      </Styled.ButtonWrapper>
                    )}
                  </Styled.CourserContainer>
                  {(index === 1 || index === 3) && <Styled.Line />}
                </>
              );
            })}
            <Styled.ButtonWrapper className="btnStyle">
              <Button
                id="logout-btn"
                title="Logout"
                className="allBtn"
                onClick={() => logOut()}
              />
            </Styled.ButtonWrapper>
          </Styled.Data>
        </Styled.MainContainer>
      )}
    </div>
  );
};
export default DropDownHeader;
