import React from 'react'
import MainLayout from '../../layout/MainLayout'

export const ContactPage = () => {
  return (
    <MainLayout>
    <h1>Contact</h1>
    </MainLayout>
  )
}
