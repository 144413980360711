import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import React, { useState } from "react";
import { Input } from "antd";
import { BiFilterAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { LuListFilter } from "react-icons/lu";
import { dataItem } from "./data";

export const CoursesInProgress = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="container">
      <Styled.MainContainer>
        <Styled.TableHeader>
          <div className="tablecontainer">
            <Styled.SearchContainer>
              <Styled.Searchbar>
                <Input
                  className="search"
                  size="large"
                  placeholder="Search"
                  prefix={<CgSearch className="searchicon" />}
                />
              </Styled.Searchbar>

              <Styled.Dropdown>
                <BiFilterAlt className="filter" />
                <Styled.DropData>
                  <select name="language" id="language" className="gsat">
                    <option value="GSAT" selected>
                      {dataItem.GSAT}
                    </option>
                  </select>
                </Styled.DropData>
              </Styled.Dropdown>

              <Styled.Filter>
                <LuListFilter className="filter" />
                <Styled.NewestFirst>
                  <select name="language" id="language" className="newest">
                    <option value="GSAT" selected>
                      {dataItem.Newestfirst}
                    </option>
                  </select>
                </Styled.NewestFirst>
              </Styled.Filter>
            </Styled.SearchContainer>
          </div>
          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>

        <AntdTable rowSelection={rowSelection}/>
      </Styled.MainContainer>
    </div>
  );
};
