import CompletedCourseModal from "../containers/Corporate/BusinessUserCompletedCourses/BusinessCompletedCourseModal/CompletedCourseModal";
import { MdDownload } from "react-icons/md";

import {
  Courses,
  InvoiceDownload,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";

export const useCompletedCourse = () => {
  const completedCourse = [
    {
      label: "Completion Date",
      mappedkey: "completionDate",
      sorter: true,
      customRender: Courses,
    },
    {
      label: "Expiry Date",
      mappedkey: "expiryDate",
      customRender: Courses,
    },
    {
      label: "Trainee Name",
      mappedkey: "traineeName",
      customRender: Courses,
    },
    {
      label: "Course Name",
      mappedkey: "courseName",
      customRender: Courses,
    },
    {
      label: "Pass/Fail",
      mappedkey: "passFail",
    },
    {
      label: "Certification",
      mappedkey: "certificate",
      customRender: Courses,
    },
    {
      label: "Download",
      mappedkey: "download",
      customRender: InvoiceDownload,
    },
  ];

  const completedCourseData = [
    {
      key: "1",
      completionDate: "25/11/2023",
      expiryDate: "25/11/2023",
      traineeName: "Graham Dani",
      courseName: "General Awareness Training Awareness",
      passFail: "50% - Pass",
      certificate: <CompletedCourseModal name={"Preview"} />,
      download: <MdDownload />,
    },
    {
      key: "2",
      completionDate: "25/11/2023",
      expiryDate: "25/11/2023",
      traineeName: "Sandra Humbe",
      courseName: "General Awareness Training Awareness",
      passFail: "92% - Pass",

      certificate: <CompletedCourseModal name={"Preview"} />,
      download: <MdDownload />,
    },
    {
      key: "3",
      completionDate: "25/11/2023",
      expiryDate: "25/11/2023",
      traineeName: "Travis Vert",
      courseName: "General Awareness Training Awareness",
      passFail: "30% - Fail",

      certificate: <CompletedCourseModal name={"Preview"} />,
      download: <MdDownload />,
    },
  ];
  return { completedCourse, completedCourseData };
};
