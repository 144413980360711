import React, { useEffect } from "react";
import * as Styled from "./style";
import Card from "../../../components/Atoms/Cards/Card";
import { TextData } from "./data";
import { useSelector } from "react-redux";
import { useCourse } from "../../../hooks/CourseHook";
import Button from "../../../components/Atoms/Button/button";
import { BiSolidRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";

export const IndividualCourses = () => {
  const { getIndividualCoursesData } = useCourse();
  const navigate = useNavigate();
  const individualCourseList = useSelector(
    (state) => state?.myCourseReducer?.IndividualCourses?.data?.orders
  );
  useEffect(() => {
    getIndividualCoursesData();
  }, []);

  const handleStartCourse = (courseId) => {
    navigate(Routes.singlePreCourse, { state: { courseId: courseId } });
  };

  return (
    <div>
      <Styled.Header>
        <div className="invitedText">{TextData.individualCourses}</div>
        <div className="text">{TextData.individualText}</div>
      </Styled.Header>

      <Styled.CardBox>
        {individualCourseList?.map((items) => {
          return (
            <>
              {items?.OrderItems?.map((coursesList) => {
                return (
                  <Card
                    data={coursesList?.Course}
                    children={
                      <Styled.StartButton>
                        <Button
                          className="start"
                          title={"START COURSE"}
                          icon={<BiSolidRightArrow />}
                          onClick={() =>
                            handleStartCourse(coursesList?.courseId)
                          }
                        />
                      </Styled.StartButton>
                    }
                  />
                );
              })}
            </>
          );
        })}
      </Styled.CardBox>
    </div>
  );
};
