import * as Styled from "./style";
import CorporateHeader from "../../components/Atoms/CorporateHeader/CorporateHeader";
import React from "react";
import Tab from "../../components/Atoms/Tab/Tab";
import { tabData } from "./ProfileConstant";
import MainLayout from "../../layout/MainLayout";

const SingleUserProfile = () => {
  return (
    <>
      <MainLayout>
        <CorporateHeader headerBackground={false} />
        <Styled.MainTableContainer>
          <Tab tabData={tabData} TabWidth={true} />
        </Styled.MainTableContainer>
      </MainLayout>
    </>
  );
};

export default SingleUserProfile;
