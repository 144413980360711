import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React from "react";
import { Checkbox } from "antd";
import { exam } from "../ExamCourse/data";
import { induction } from "./data";

const InductionCheckData = () => {
  return (
    <>
      <Styled.ExamData>
        <div className="exam">
          {induction.induction}
          <span className="form">{induction.check}</span>
        </div>
        <Styled.Security>
          <div>
            <div className="name">
              {exam.name}
              <span className="john">{exam.john}</span>
            </div>
            <div className="name">
              {exam.title}
              <span className="john">{exam.general}</span>
            </div>
          </div>
        </Styled.Security>
        <div className="how">{induction.boxes}</div>
        <Styled.Tables>
          <table style={{ width: "651px" }} className="tableData">
            <colgroup>
              <col span="1" style={{ width: "87%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>{induction.and}</th>
                <th className="column-style">
                  <Checkbox />
                  <div className="tick">{induction.tick}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{induction.eq}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.protection}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.app}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.mal}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.safety}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>{" "}
              <tr>
                <td>{induction.whistleblowing}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.appeal}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
            </tbody>
          </table>
        </Styled.Tables>
        <Styled.Tables>
          <table style={{ width: "651px" }} className="tableData">
            <colgroup>
              <col span="1" style={{ width: "87%" }} />
            </colgroup>
            <thead>
              <tr>
                <th>{induction.course}</th>
                <th className="column-style">
                  <Styled.Checked />

                  <div className="tick">{induction.tick}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{induction.training}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.aims}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.st}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.dates}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.supp}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>{" "}
              <tr>
                <td>{induction.staff}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.require}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.cont}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>{" "}
              <tr>
                <td>{induction.provided}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
              <tr>
                <td>{induction.used}</td>
                <td className="column-style">
                  <Checkbox />
                </td>
              </tr>
            </tbody>
          </table>
        </Styled.Tables>
        <Styled.Acknowledge>
          <div className="ackno">
            <div className="train">{induction.trainee}</div>
            <div className="cover">{induction.covering}</div>
          </div>
          <div>
            <div className="comment">{induction.comm}</div>
            <Styled.Text />
            <Styled.Line />
            <Styled.Sign>
              <div className="signs">
                {induction.sign}
                <Styled.Input type="text" />
              </div>
              <div className="datesS">
                {induction.dateS}
                <Styled.InputDate type="text" placeholder="DD/MM/YYYY" />
              </div>
            </Styled.Sign>
          </div>
        </Styled.Acknowledge>
        <div className="secured">
          <Button title={"Submit Checklist"} className="start" />
        </div>
      </Styled.ExamData>
    </>
  );
};

export default InductionCheckData;
