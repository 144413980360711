import * as Styled from "./style";
import FeedbackData from "./FeedbackData";
import React, { useEffect } from "react";
import { RxSlash } from "react-icons/rx";
import { course } from "../FirstScreen/data";
import { HeaderTraining } from "../Header/HeaderTraining";
import Footer from "../../../components/Organisms/Footer/Footer";
import HttpApis from "../../../services/HttpApis";
import { feedBackSuccess } from "../../../redux/slices/feedBackSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetch_feedback = async () => {
      try {
        const response = await HttpApis.getFeedBackList();
        dispatch(feedBackSuccess(response.data.data));
      } catch (error) {
        console.log("error");
      }
    };
    fetch_feedback();
  }, []);
  return (
    <>
      <HeaderTraining />
      <Styled.Training>
        <div className="secure">
          <div className="cor">
            {course.cor}
            <RxSlash className="slash" />
            {location?.state?.title}
          </div>
        </div>
        <div className="container">
          <FeedbackData
            courseId={location?.state?.courseId}
            title={location?.state?.title}
          />
        </div>
      </Styled.Training>
      <Footer />
    </>
  );
};

export default FeedbackForm;
