import * as Styled from "./style";
import React from "react";

const Tab = (props) => {
  const { tabData, TabWidth } = props;
  const onChange = (key) => {
  };
  
  return (
    <Styled.Tab 
      defaultActiveKey="0"
      items={tabData}
      TabWidth={TabWidth}
      onChange={onChange}
      style={{
        shadowOffset: { height: 0, width: 0 },
        shadowColor: "transparent",
        shadowOpacity: 0,
        elevation: 0,
      }}
    />
  );
};

export default Tab;
