import React from "react";
import MainLayout from "../../layout/MainLayout";

export const AboutUsPage = () => {
  return (
    <MainLayout>
      <h1>About Us</h1>
    </MainLayout>
  );
};
