import * as Styled from "./style";
import AVSECCoursesCard from "../../../components/Atoms/CoursesCard/AVSECCoursesCard";
import AntdTable from "../../../components/Atoms/Table";
import Button from "../../../components/Atoms/Button/button";
import CoursesDetail from "./CoursesDetail";
import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { BiFilterAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { LuListFilter } from "react-icons/lu";
import { MdAdd } from "react-icons/md";
import { useCourse } from "../../../hooks/CourseHook";
import { useLicense } from "../../../hooks/LicenseHook";
import { dataItem } from "./ButtonData";

const Courses = () => {
  const [isPageOpen, setIsPageOpen] = useState(true);
  const { TableCourse, tableData, show } = useCourse();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const { fetchLicensesList } = useLicense();

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleClickCourse = () => {
    setIsPageOpen(!isPageOpen);
  };
  useEffect(() => {
    fetchLicensesList();
  }, []);

  return (
    <>
      {show === false ? (
        <>
          {isPageOpen ? (
            <div className="container">
              <Styled.AddCourseContainer>
                <Styled.Courses>
                  <span className="courses">{dataItem.Courses}</span>
                  <Styled.Pipe />
                  <span className="number">{dataItem.Number}</span>
                </Styled.Courses>
                <Styled.AddCourse>
                  <Button
                    icon={<MdAdd size={14} className="plus" />}
                    title={"Add Courses"}
                    className="add"
                    type="primary"
                    onClick={() => handleClickCourse()}
                  ></Button>
                </Styled.AddCourse>
              </Styled.AddCourseContainer>

              <Styled.BelowText>{dataItem.BelowText}</Styled.BelowText>
              <Styled.MainContainer>
                <Styled.TableHeader className="container">
                  <div className="tablecontainer">
                    <Styled.Searchbar>
                      <Input
                        className="search"
                        size="large"
                        placeholder="Search"
                        prefix={<CgSearch className="searchicon" />}
                      />
                    </Styled.Searchbar>
                    <Styled.Dropdown>
                      <BiFilterAlt className="filter" />
                      <Styled.DropData>
                        <select name="language" id="language" className="gsat">
                          <option value="active" selected>
                            {dataItem.Active}
                          </option>
                        </select>
                      </Styled.DropData>
                    </Styled.Dropdown>
                    <Styled.Filter>
                      <LuListFilter className="filter" />
                      <Styled.NewestFirst>
                        <select
                          name="language"
                          id="language"
                          className="newest"
                        >
                          <option value="GSAT" selected>
                            {dataItem.Newestfirst}
                          </option>
                        </select>
                      </Styled.NewestFirst>
                    </Styled.Filter>
                  </div>
                  <Styled.DotIcon>
                    <BsThreeDotsVertical className="dots" />
                  </Styled.DotIcon>
                </Styled.TableHeader>
                <div>
                  <AntdTable
                    data={tableData}
                    columns={TableCourse}
                    rowSelection={rowSelection}
                  />
                </div>
              </Styled.MainContainer>
            </div>
          ) : (
            <AVSECCoursesCard
              isPageOpen={true}
              iswidth={false}
              handleClickCourse={handleClickCourse}
            />
          )}
        </>
      ) : (
        <CoursesDetail />
      )}
    </>
  );
};

export default Courses;
