import { CompletedCourses } from "../BusinessUserCompletedCourses/CompletedCourses";
import { CoursesInProgress } from "../BusinessUserCoursesInProgress/CoursesInProgress";

export const buttonData = [
  {
    id: 0,
    title: "Complete | 2",
    child: <CompletedCourses />,
  },
  {
    id: 1,
    title: "In Progress | 40",
    child: <CoursesInProgress />,
  },
];

export const dataItem = {
  Courses: "Courses",
  Number: "5",
  BelowText: "Below is a list of the certificates of courses",
  Active: "Active",
  Newestfirst: "Newest first",
  DownloadSpreadsheet: "Download Spreadsheet",
  Back: "Back to list",
  CourseName: "General Security Awareness Training(GSAT)"
};
