import React from 'react'
import MainLayout from '../../layout/MainLayout'

export const ResourcesPage = () => {
  return (
    <MainLayout>
    <h1>Resources</h1>
    </MainLayout>
  )
}
