import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;
export const TextContainer = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  .courses {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-family: Open Sans;
    margin-top: 1px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Belowtext = styled.div`
  color: ${color._68828e};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MainContainer = styled.div`
  margin-top: 26px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
`;
export const TableHeader = styled.div`
  padding: 13px 20px 13px 17px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 14px;
  .tablecontainer {
    display: flex;
    gap: 12px;
  }
`;
export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;
export const Searchbar = styled.div`
  .ant-input-affix-wrapper .ant-input-prefix {
    width: 12px;
    margin-top: 1px;
  }
  .searchicon {
    margin-top: 5px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0px;
  }
  display: flex;
  align-items: center;
  padding-left: 9px;
  align-items: center;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  .search {
    border: none;
  }
  .search {
    gap: 1px;
  }

  ::placeholder {
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Dropdown = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 100%;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;
export const DropData = styled.div`
  width: 100%;
  .gsat {
    width: 100%;
    border: none;
    padding-right: 92px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Filter = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 100%;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;
export const NewestFirst = styled.div`
  .newest {
    border: none;
    padding-right: 82px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const ProgressText = styled.div`
  color: ${color._16384E};
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding: 0px 0px 20px 0px;
`;
export const ShaunText = styled.div`
  color: ${color._0073DE};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const ShaunLine = styled.div`
  width: 78px;
  height: 3px;
  background: ${color._0073de};
  margin-top: 17px;
`;
export const BorderLine = styled.hr`
  margin: 0;
  height: 1px;
  color: ${color._D9D9D9};
`;
export const Text = styled.div`
  color: ${color._D9D9D9};
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding-top: 195px;
`;
export const ModalBox = styled.div`
  .ant-modal-content {
    padding: 40px 30px;
  }

  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;
