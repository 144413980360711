import HttpApis from "../services/HttpApis";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CourseColor } from "../containers/Corporate/BusinessUserLicense/CustomRender";
import { UpdatedCoursesList } from "../redux/slices/coursesSlice";
import {
  myCourseSuccess,
  individualCoursesSuccess,
} from "../redux/slices/myCourseSlice";
import { Routes } from "../routes/routes";

import {
  Courses,
  ActiveTag,
  CoursesDetail,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";

export const useCourse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courses = useSelector((state) => state?.coursesReducer?.courses);
  const licenseRelatedCourses = useSelector(
    (state) => state?.licenseReducer?.licenseList?.licenses
  );

  const handleClick = (slug) => {
    navigate(`${Routes?.multiUserDetailPage}/${slug}`);
  };

  const getMyCourseData = async () => {
    try {
      const response = await HttpApis.getMyCourse();
      dispatch(myCourseSuccess(response?.data));
    } catch (error) {
      console.log("error");
    }
  };

  const getIndividualCoursesData = async () => {
    try {
      const response = await HttpApis.getIndividualCourse();
      dispatch(individualCoursesSuccess(response?.data));
    } catch (error) {
      console.log("error");
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await HttpApis.coursesList();
        dispatch(UpdatedCoursesList(response?.data?.data));
      } catch (error) {
        console.error(error);
      }
    };

    fetchCourses();
  }, []);

  const [currentlyOpenMenu, setCurrentlyOpenMenu] = useState(null);
  const [show, setShow] = useState(false);

  const handleMenuClick = (key) => {
    if (currentlyOpenMenu === key) {
      setCurrentlyOpenMenu(null);
    } else {
      setCurrentlyOpenMenu(key);
    }
  };

  const handleShow = () => {
    setShow(!show);
  };

  const TableCourse = [
    {
      label: "Date Added",
      mappedkey: "dateAdded",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: Courses,
    },
    {
      label: "Course Name",
      mappedkey: "courseName",
      customRender: CourseColor,
      action: handleShow,
    },
    {
      label: "Licenses",
      mappedkey: "licenses",
      customRender: Courses,
    },
    {
      label: "Completed",
      mappedkey: "completed",
      customRender: CoursesDetail,
    },
    {
      label: "Active Licenses",
      mappedkey: "active",
      customRender: ActiveTag,
    },
    {
      label: "Actions",
      mappedkey: "actions",
      customRender: Courses,
    },
  ];
  const tableData = licenseRelatedCourses?.map((item) => ({
    key: "1",
    dateAdded: item?.course?.createdAt.split("T")[0],
    courseName: item?.course?.title,
    licenses: item?.qty,
    completed: "0",
    active: "0",
    // actions: (
    //   <ActionMenu
    //     currentlyOpenMenu={currentlyOpenMenu}
    //     onClick={handleMenuClick}
    //     menuKey="1"
    //   />
    // ),
  }));

  return {
    TableCourse,
    tableData,
    show,
    setShow,
    handleClick,
    courses,
    getMyCourseData,
    getIndividualCoursesData,
  };
};
