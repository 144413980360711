import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React from "react";
import { Checkbox } from "antd";
import { exam } from "../ExamCourse/data";
import { question } from "./data";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";

const FormData = ({ data }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(Routes?.feedbackForm);
  };
  return (
    <>
      <Styled.ExamData>
        <div className="exam">{exam.courseExam}</div>
        <Styled.Security>
          <div>
            <div className="name">
              {exam.name}
              <span className="john">{data?.state?.username}</span>
            </div>
            <div className="name">
              {exam.title}
              <span className="john">{data?.state?.title}</span>
            </div>
          </div>
        </Styled.Security>
        <Styled.SingleLine />
        <Styled.Question>
          <div className="ques">{question.ques}</div>
          <div className="inside">{question.insider}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.job}</Checkbox>
            <br />
            <Checkbox>{question.mail}</Checkbox>
            <br />
            <Checkbox>{question.found}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques2}</div>
          <div className="inside">{question.civil}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.mass}</Checkbox>
            <br />
            <Checkbox>{question.duty}</Checkbox>
            <br />
            <Checkbox>{question.rest}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques3}</div>
          <div className="inside">{question.person}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.detain}</Checkbox>
            <br />
            <Checkbox>{question.build}</Checkbox>
            <br />
            <Checkbox>{question.imm}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques4}</div>
          <div className="inside">{question.sets}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.intern}</Checkbox>
            <br />
            <Checkbox>{question.depart}</Checkbox>
            <br />
            <Checkbox>{question.ec}</Checkbox>
            <br />
            <Checkbox>{question.police}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques5}</div>
          <div className="inside">{question.organisation}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.center}</Checkbox>
            <br />
            <Checkbox>{question.europe}</Checkbox>
            <br />
            <Checkbox>{question.aviat}</Checkbox>
            <br />
            <Checkbox>{question.interIC}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques6}</div>
          <div className="inside">{question.resp}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.manager}</Checkbox>
            <br />
            <Checkbox>{question.every}</Checkbox>
            <br />
            <Checkbox>{question.caa}</Checkbox>
            <br />
            <Checkbox>{question.staff}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques7}</div>
          <div className="inside">{question.overall}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.checkAir}</Checkbox>
            <br />
            <Checkbox>{question.ensure}</Checkbox>
            <br />
            <Checkbox>{question.pass}</Checkbox>
            <br />
            <Checkbox>{question.prevents}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques8}</div>
          <div className="inside">{question.conducting}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.possiblity}</Checkbox>
            <br />
            <Checkbox>{question.emp}</Checkbox>
            <br />
            <Checkbox>{question.ens}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques9}</div>
          <div className="inside">{question.overall}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.passport}</Checkbox>
            <br />
            <Checkbox>{question.tailgating}</Checkbox>
            <br />
            <Checkbox>{question.valids}</Checkbox>
            <br />
            <Checkbox>{question.passport}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques10}</div>
          <div className="inside">{question.discover}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.bags}</Checkbox>
            <br />
            <Checkbox>{question.finds}</Checkbox>
            <br />
            <Checkbox>{question.nearbys}</Checkbox>
            <br />
            <Checkbox>{question.sra}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques11}</div>
          <div className="inside">{question.contribute}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.procedures}</Checkbox>
            <br />
            <Checkbox>{question.enters}</Checkbox>
            <br />
            <Checkbox>{question.needs}</Checkbox>
            <br />
            <Checkbox>{question.socials}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques12}</div>
          <div className="inside">{question.contribute}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.losing}</Checkbox>
            <br />
            <Checkbox>{question.restricted}</Checkbox>
            <br />
            <Checkbox>{question.colleague}</Checkbox>
            <br />
            <Checkbox>{question.ids}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques13}</div>
          <div className="inside">{question.airside}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.aerosols}</Checkbox>
            <br />
            <Checkbox>{question.sharp}</Checkbox>
            <br />
            <Checkbox>{question.lights}</Checkbox>
            <br />
            <Checkbox>{question.keyys}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques14}</div>
          <div className="inside">{question.article}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.areaCheck}</Checkbox>
            <br />
            <Checkbox>{question.baggage}</Checkbox>
            <br />
            <Checkbox>{question.duties}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques15}</div>
          <div className="inside">{question.landside}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.dutiess}</Checkbox>
            <br />
            <Checkbox>{question.runs}</Checkbox>
            <br />
            <Checkbox>{question.chck}</Checkbox>
            <br />
            <Checkbox>{question.departLon}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques16}</div>
          <div className="inside">{question.granted}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.airPass}</Checkbox>
            <br />
            <Checkbox>{question.visual}</Checkbox>
            <br />
            <Checkbox>{question.uniforms}</Checkbox>
            <br />
            <Checkbox>{question.compBrand}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques17}</div>
          <div className="inside">{question.escorting}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.empIssue}</Checkbox>
            <br />
            <Checkbox>{question.passCarry}</Checkbox>
            <br />
            <Checkbox>{question.sights}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques18}</div>
          <div className="inside">{question.collectingBelow}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.proves}</Checkbox>
            <br />
            <Checkbox>{question.photographic}</Checkbox>
            <br />
            <Checkbox>{question.completionEv}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques19}</div>
          <div className="inside">{question.onced}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.dest}</Checkbox>
            <br />
            <Checkbox>{question.transferable}</Checkbox>
            <br />
            <Checkbox>{question.ret}</Checkbox>
            <br />
            <Checkbox>{question.works}</Checkbox>
          </div>
        </Styled.Question>
        <Styled.Question>
          <div className="ques">{question.ques20}</div>
          <div className="inside">{question.whilst}</div>
          <div className="select">{question.select}</div>
          <div className="job">
            <Checkbox>{question.escortee}</Checkbox>
            <br />
            <Checkbox>{question.seemslike}</Checkbox>
            <br />
            <Checkbox>{question.linedd}</Checkbox>
          </div>
        </Styled.Question>
        <div className="secured">
          <Button
            title={"Finish Exam"}
            className="start"
            onClick={() => handleClick()}
          />
        </div>
      </Styled.ExamData>
    </>
  );
};

export default FormData;
