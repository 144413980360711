import { useSelector } from "react-redux";
import { Routes } from "../routes/routes";

export const useDropdown = () => {
  const userType = useSelector((state) => state?.authReducer?.user?.type);

  const isUserTypePresent = (type) => {
    const types = ["Corporate User", "Finance Account", "Operations"];
    return types.includes(type);
  };
  const btnData = [
    {
      id: 0,
      title: "My courses",
      path: "#",
    },
    { id: 1, title: "My cart", count: "2", path: Routes?.multiCart },
    {
      id: 2,
      title: "Account Settings",
      path: isUserTypePresent(userType)
        ? Routes?.userProfile
        : Routes?.singleUserProfile,
    },
    { id: 3, title: "Payment Methods", path: "#" },
    { id: 4, title: "Help", path: "#" },
  ];
  return { btnData };
};
