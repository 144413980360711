export const dataItem = {
  Courses: "Courses in Progress",
  Number: "3",
  BelowText:
    "Below is a list of the courses in progress for each enrolled trainee.",
  GSAT: "GSAT",
  Newestfirst: "Newest first",
};
export const ModalText={
   ProgressStats:"Progress Stats",
   ShaunMurphy:"Shaun Murphy",
   Stats:"Stats displayed here"
}
