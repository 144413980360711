import * as Styled from "./style";
import HeaderPre from "./HeaderPre";
import Malpractice from "./Malpractice";
import React, { useEffect, useState } from "react";
import usePreCourseQuestionHook from "../../hooks/PreCourseQuestionHook";
import { Checkbox } from "antd";
import { useSelector } from "react-redux";
import { course } from "./data";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../routes/routes";
import { message } from "antd";

const PreCourseInvited = () => {
  const navigate = useNavigate();
  const { getPreCourseData, handleAnswersData } = usePreCourseQuestionHook();
  const [questionsList, setQuestionList] = useState([]);
  const preCourseList = useSelector(
    (state) => state?.preCourseQuestionReducer?.preCourseQuestion?.data
  );
  const [preCoursedata, setPreCourseData] = useState([]);
  const location = useLocation();
  const courseId = location?.state?.courseId;
  const userDetail = useSelector((state) => state?.authReducer?.user);

  const handleChange = (question_id, e, index) => {
    const value = e.target.value;

    if (e.target.type === "checkbox") {
      const existingIndex = preCoursedata?.findIndex(
        (q) => q?.question_id === question_id
      );
      if (existingIndex > -1) {
        const newQuestion = { ...preCoursedata[existingIndex] };
        if (e.target.checked) {
          newQuestion.answers_id = [...newQuestion.answers_id, value];
          if (
            questionsList[index]?.Answers?.find((ans) => ans?.id === value)
              ?.answer_text === "Other"
          ) {
            newQuestion.hintText = "";
            newQuestion.isHint = 1;
          }
        } else {
          newQuestion.answers_id = newQuestion?.answers_id?.filter(
            (id) => id !== value
          );
          if (
            questionsList[index]?.Answers?.find((ans) => ans?.id === value)
              ?.answer_text === "Other"
          ) {
            newQuestion.hintText = null;
            newQuestion.isHint = 0;
          }
        }
        const allQuestions = [...preCoursedata];
        allQuestions.splice(existingIndex, 1, newQuestion);
        setPreCourseData(allQuestions);
      } else if (e.target.checked) {
        setPreCourseData([
          ...preCoursedata,
          {
            courseId: courseId,
            question_id: question_id,
            answers_id: [value],
            isHint:
              questionsList[index]?.Answers?.find((ans) => ans?.id === value)
                ?.answer_text === "Other"
                ? 1
                : 0,
            hintText:
              questionsList[index]?.Answers?.find((ans) => ans?.id === value)
                ?.answer_text === "Other"
                ? ""
                : null,
          },
        ]);
      }
    } else if (e.target.type === "text" || e.target.type === "textarea") {
      const existingIndex = preCoursedata?.findIndex(
        (q) => q?.question_id === question_id
      );

      if (existingIndex > -1) {
        const newQuestion = { ...preCoursedata[existingIndex] };
        if (newQuestion.isHint === 1) {
          newQuestion.hintText = value;
        } else {
          newQuestion.answers_id = [value];
          newQuestion.isHint = 0;
          newQuestion.hintText = null;
        }
        setPreCourseData((prevData) => {
          const allQuestions = [...prevData];
          allQuestions.splice(existingIndex, 1, newQuestion);
          return allQuestions;
        });
      } else {
        setPreCourseData((prevData) => [
          ...prevData,
          {
            courseId: courseId,
            question_id: question_id,
            answers_id: [value],
            isHint: 0,
            hintText: null,
          },
        ]);
      }
    } else if (e.target.type === "select-one") {
      if (value === "Select Pronoun") {
        setPreCourseData(
          preCoursedata?.filter((data) => data?.question_id !== question_id)
        );
      } else {
        const existingIndex = preCoursedata?.findIndex(
          (q) => q?.question_id === question_id
        );

        if (existingIndex > -1) {
          const newQuestion = { ...preCoursedata[existingIndex] };
          newQuestion.answers_id = [value];
          const allQuestions = [...preCoursedata];
          allQuestions.splice(existingIndex, 1, newQuestion);
          setPreCourseData(allQuestions);
        } else {
          setPreCourseData([
            ...preCoursedata,
            {
              courseId: courseId,
              question_id: question_id,
              answers_id: [value],
              isHint: 0,
              hintText: null,
            },
          ]);
        }
      }
    } else if (e.target.type === "radio") {
      const question = questionsList[index];
      if (question?.Answers) {
        const selectedAnswer = question?.Answers?.find((a) => a?.id == value);
        const children = questionsList?.filter(
          (q) => q?.parent_id === question?.id
        );
        const _questionsList = [...questionsList];
        if (selectedAnswer?.answer_text === "Yes") {
          // show children
          children.map((q) => {
            const _q = { ...q };
            const existingQIndex = _questionsList?.findIndex(
              (item) => item?.id === _q.id
            );
            _q.show = true;
            _questionsList.splice(existingQIndex, 1, _q);
          });
        } else {
          //hide children
          children.map((q) => {
            const _q = { ...q };
            const existingQIndex = _questionsList?.findIndex(
              (item) => item?.id === _q?.id
            );
            _q.show = false;
            _questionsList.splice(existingQIndex, 1, _q);
          });
        }
        setQuestionList(_questionsList);
      }

      const existingIndex = preCoursedata?.findIndex(
        (item) => item?.question_id === question_id
      );
      if (existingIndex !== -1) {
        const updatedPreCourseData = [...preCoursedata];
        updatedPreCourseData[existingIndex] = {
          ...updatedPreCourseData[existingIndex],
          answers_id: [value],
        };
        setPreCourseData(updatedPreCourseData);
      } else {
        setPreCourseData([
          ...preCoursedata,
          {
            courseId: courseId,
            question_id: question_id,
            answers_id: [value],
            isHint: 0,
            hintText: null,
          },
        ]);
      }
    }
  };

  const result = preCourseList?.filter(
    (item) => item.parent_id === 0 && item.questionType !== "label"
  );

  const handleTrainingButton = async (isCheckboxChecked) => {
    if (preCoursedata.length === 0) {
      message.error("Please fill the answers before submitting.");
      return;
    }
    let invalid = false;
    result.map((q) => {
      const qt = preCoursedata.find((k) => k.question_id === q.id);
      if (!qt) {
        invalid = true;
      }
    });

    if (invalid) {
      message.error("Please fill all the answers before submitting.");
      return;
    }

    if (!isCheckboxChecked) {
      message.error("Please accept the Policies before submitting.");
      return;
    }
    if (isCheckboxChecked) {
      const data = await handleAnswersData(preCoursedata);
      if (data?.payload?.status) {
        navigate(`${Routes?.helloJohn}`);
      } else {
        console.error(
          `API call failed with status code ${data?.payload?.status}`
        );
      }

      setPreCourseData([]);
    }
  };

  useEffect(() => {
    setQuestionList(preCourseList);
  }, [preCourseList]);

  useEffect(() => {
    getPreCourseData("formType=1");
  }, []);

  return (
    <>
      <Styled.SecondRow>
        <div className="need">{course?.needs}</div>
      </Styled.SecondRow>
      <Styled.MainForm>
        <div className="mains">
          <div className="please">
            <div className="name">
              {course?.name}
              <span className="john">
                {userDetail?.firstName + " " + userDetail?.lastName}
              </span>
            </div>
            {questionsList?.map((item, index) => {
              return item?.parent_id === 0 || item?.show === true ? (
                <div>
                  <Styled.Label>
                    {item?.question}
                    {item?.parent_id === 0 && <span className="star">*</span>}
                  </Styled.Label>
                  <div className="subQuestion">{item?.subQuestion}</div>

                  {item?.questionType === "singlechoice" ? (
                    item?.questionSubType === "Selecttype" ? (
                      <select
                        className="select"
                        onChange={(e) => handleChange(item?.id, e)}
                      >
                        <option value="Select Pronoun">Select Pronoun</option>
                        {item?.Answers?.map((add) => {
                          return (
                            <option value={add?.id} name="answers_id">
                              {add?.answer_text}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <div className="yesNo">
                        {item?.Answers?.map((add) => {
                          return (
                            <div className="singleChoiceText">
                              <input
                                className="radio"
                                type="radio"
                                name={item?.id}
                                value={add?.id}
                                onChange={(e) =>
                                  handleChange(item?.id, e, index)
                                }
                              />
                              <spna className="yes"> {add?.answer_text}</spna>
                            </div>
                          );
                        })}
                      </div>
                    )
                  ) : item?.questionType === "multiplechoice" ? (
                    <div className="wrapSecond">
                      {item?.Answers?.map((add) => {
                        return (
                          <div>
                            <Styled.Deaf>
                              <div className="change">
                                <Checkbox
                                  className="deaf"
                                  name="answers_id"
                                  value={add?.id}
                                  onChange={(e) =>
                                    handleChange(item?.id, e, index)
                                  }
                                >
                                  {add?.answer_text}
                                </Checkbox>
                                {add?.isHint === 1 &&
                                preCoursedata?.find(
                                  (data) => data?.question_id === item?.id
                                )?.isHint === 1 ? (
                                  <input
                                    className="otherInput"
                                    type="text"
                                    placeholder="Please enter here"
                                    value={
                                      preCoursedata?.find(
                                        (data) => data?.question_id === item?.id
                                      )?.hintText || ""
                                    }
                                    onChange={(e) =>
                                      handleChange(item?.id, e, index)
                                    }
                                  />
                                ) : null}
                              </div>
                            </Styled.Deaf>
                          </div>
                        );
                      })}
                    </div>
                  ) : item?.questionType === "opentext" ? (
                    <div className="disability">
                      {item?.questionSubType === "Inputtype" ? (
                        <Styled.InputData
                          type="text"
                          name="answers_id"
                          value={
                            preCoursedata?.find(
                              (data) => data?.question_id === item?.id
                            )?.answers_id[0] || ""
                          }
                          onChange={(e) => handleChange(item?.id, e, index)}
                        />
                      ) : (
                        <Styled.TextArea
                          rows="4"
                          cols="65"
                          type="text"
                          name="answers_id"
                          value={
                            preCoursedata?.find(
                              (data) => data?.question_id === item?.id
                            )?.answers_id[0] || ""
                          }
                          onChange={(e) => handleChange(item?.id, e, index)}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null;
            })}
          </div>
        </div>
      </Styled.MainForm>
      <Malpractice handleTrainingButton={handleTrainingButton} />
    </>
  );
};

export default PreCourseInvited;
