import Footer from "../../components/Organisms/Footer/Footer";
import CoursesModule from "../../containers/TrainingScreen/FirstScreen/CoursesModule";
import React from "react";

const TrainingCoursesPage = () => {
  return <><CoursesModule />
  <Footer/>
  </>;
};

export default TrainingCoursesPage;
