import * as Styled from "./style";
import InductionCheckData from "./InductionCheckData";
import React from "react";
import { RxSlash } from "react-icons/rx";
import { course } from "../FirstScreen/data";
import { HeaderTraining } from "../Header/HeaderTraining";
import Footer from "../../../components/Organisms/Footer/Footer";

const InductionChecklist = () => {
  return (
    <>
      <HeaderTraining />
      <Styled.Training>
        <div className="secure">
          <div className="cor">
            {course.cor}
            <RxSlash className="slash" />
            {course.generals}
          </div>
        </div>
        <div className="container">
          <InductionCheckData />
        </div>
      </Styled.Training>
      <Footer />
    </>
  );
};

export default InductionChecklist;
