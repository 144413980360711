import CorporateHeader from "../../components/Atoms/CorporateHeader/CorporateHeader";
import React from "react";
import Tab from "../../components/Atoms/Tab/Tab";
import { tabData } from "./constants";
import MainLayout from "../../layout/MainLayout";
import { useSelector } from "react-redux";
import * as Styled from "./style";

const UserProfile = () => {
  const user = useSelector((state) => state.authReducer.user);
  const { type, all_permission } = user;
  const permissions = all_permission?.data?.filter((item) => {
    return item?.UserHasPermission?.status === "Active";
  });
  const roleTabData = permissions?.map((item, index) => {
    return {
      key: index,
      label: item?.Permission?.name,
      children: tabData.filter(
        (data) => data?.label === item?.Permission?.name
      )[0]?.children,
    };
  });

  return (
    <>
      <MainLayout>
        <CorporateHeader headerBackground={true} />
        {type === "Corporate User" ? (
          <Styled.MainTableContainer>
            <Tab tabData={tabData} />
          </Styled.MainTableContainer>
        ) : (
          <Styled.MainTableContainer>
            <Tab tabData={roleTabData} />
          </Styled.MainTableContainer>
        )}
      </MainLayout>
    </>
  );
};

export default UserProfile;
