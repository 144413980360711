import * as Styled from "./style";
import Footer from "../../../../components/Organisms/Footer/Footer";
import React, { useState } from "react";
import { Modal } from "antd";
import { AiFillEye } from "react-icons/ai";
import { GiEarthAmerica } from "react-icons/gi";
import { MdClose, MdDownload } from "react-icons/md";
import { ReactComponent as BusinessLogo } from "../../../../assets/Svg/BusinessLogo.svg";
import { ModalItem } from "../data";

const CompletedCourseModal = ({ name }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <a type="primary" onClick={() => setModalOpen(true)}>
        <u>{name}</u>
      </a>
      <Styled.ModalContainer
        style={{
          top: 20,
        }}
        width={850}
        footer={null}
        closeIcon={false}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        <Styled.ModalHeader>
          <Styled.ModalTitle>{ModalItem.certificateModal}</Styled.ModalTitle>
          <Styled.Icon onClick={() => setModalOpen(false)}>
            <MdClose className="closeIcon" />
          </Styled.Icon>
        </Styled.ModalHeader>
        <Styled.ModalBox>
          <Styled.CourseName>
            <div className="head">{ModalItem.course}</div>
            <div className="general">
              <BusinessLogo />
              {ModalItem.general} <span className="gsat">{ModalItem.gsat}</span>
            </div>
            <div className="english">
              <GiEarthAmerica />
              {ModalItem.eng}
            </div>
          </Styled.CourseName>
          <Styled.Line />
          <Styled.Receipt>
            <div>
              <div className="recName">{ModalItem.recName}</div>
              <div className="staceyM">{ModalItem.stacey}</div>
            </div>
            <div>
              <div className="recName">{ModalItem.institute}</div>
              <div className="staceyM">{ModalItem.email}</div>
            </div>
            <div>
              <div className="recName">{ModalItem.issue}</div>
              <div className="staceyM">23/06/2022</div>
            </div>
          </Styled.Receipt>
          <Styled.Line />
          <Styled.Preview>
            <div className="certify">
              <AiFillEye />
              {ModalItem.preview}
            </div>
            <div className="download">
              <MdDownload className="downloadIcon" />
              {ModalItem.download}
            </div>
          </Styled.Preview>
        </Styled.ModalBox>
        <Styled.Training>
          <div className="expire">{ModalItem.expire}</div>
          <div className="valid">{ModalItem.training}</div>
        </Styled.Training>
      </Styled.ModalContainer>
    </>
  );
};
export default CompletedCourseModal;
