import * as Styled from "./style";
import Accordion from "react-bootstrap/Accordion";
import React from "react";
import quiz from "../../../assets/Svg/quiz.svg";
import { Checkbox, Radio } from "antd";
import { course } from "./data";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "../../../routes/routes";

const SideBarPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleExamRoute = () => {
    navigate(Routes.examCourse, { state: location?.state });
  };
  return (
    <Styled.MainModule>
      <div>
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Styled.Mods>
                <Checkbox className="mod1">{course.mod1}</Checkbox>
                <div className="mins">
                  {course.mins}
                  <span className="min15">{course.min15}</span>
                </div>
              </Styled.Mods>
            </Accordion.Header>
            <Accordion.Body>
              <Styled.AimsBody>
                <div className="aimsLevel">
                  <Radio className="body">{course.aims}</Radio>
                  <Radio className="body">{course.def}</Radio>
                  <Radio className="body">{course.level}</Radio>
                  <Radio className="body">{course.threat}</Radio>
                  <Radio className="body">{course.civil}</Radio>
                  <Radio className="body">{course.pose}</Radio>
                  <Radio className="body">{course.pose}</Radio>
                  <div className="quiz">
                    <img src={quiz} /> Quiz
                  </div>
                  <Radio className="body">{course.motiv}</Radio>
                  <Radio className="body">{course.attacks}</Radio>
                  <Radio className="body">{course.civil}</Radio>
                  <div className="quiz">
                    <img src={quiz} />
                    Quiz
                  </div>
                  <Radio className="body">{course.sum}</Radio>
                </div>
              </Styled.AimsBody>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <Styled.Mods>
                <Checkbox className="mod1">{course.mod2}</Checkbox>
                <div className="mins">
                  {course.sec}
                  <span className="min15">{course.min15}</span>
                </div>
              </Styled.Mods>
            </Accordion.Header>
            <Accordion.Body>
              <Styled.AimsBody>
                <div className="aimsLevel">
                  <Radio className="body">{course.aims}</Radio>
                  <Radio className="body">{course.overall}</Radio>
                  <Radio className="body">{course.localPro}</Radio>
                  <Radio className="body">{course.rem}</Radio>
                  <Radio className="body">{course.civil}</Radio>
                  <Radio className="body">{course.who}</Radio>
                  <Radio className="body">{course.resp}</Radio>
                  <div className="quiz">
                    <img src={quiz} /> Quiz
                  </div>
                  <Radio className="body">{course.direc}</Radio>
                  <Radio className="body">{course.obligations}</Radio>
                  <Radio className="body">{course.everyones}</Radio>
                  <Radio className="body">{course.sum}</Radio>
                </div>
              </Styled.AimsBody>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <Styled.Mods>
                <Checkbox className="mod1">{course.mod3}</Checkbox>
                <div className="mins">
                  {course.trainee}
                  <span className="min15">{course.min15}</span>
                </div>
              </Styled.Mods>
            </Accordion.Header>
            <Accordion.Body>
              <Styled.AimsBody>
                <div className="aimsLevel">
                  <Radio className="sum">{course.quiz}</Radio>
                  <Radio className="sum">{course.quiz}</Radio>
                  <Radio className="sum">{course.sum}</Radio>
                </div>
              </Styled.AimsBody>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <Styled.Mods>
                <Checkbox className="mod1">{course.mod4}</Checkbox>
                <div className="mins">
                  {course.accessScreen}
                  <span className="min15">{course.min15}</span>
                </div>
              </Styled.Mods>
            </Accordion.Header>
            <Accordion.Body>
              <Styled.AimsBody>
                <div className="aimsLevel">
                  <Radio className="sum">{course.quiz}</Radio>
                  <Radio className="sum">{course.quiz}</Radio>
                  <Radio className="sum">{course.sum}</Radio>
                </div>
              </Styled.AimsBody>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <Styled.Mods>
                <Checkbox className="mod1">{course.moded5}</Checkbox>
                <div className="mins">
                  {course.employment}
                  <span className="min15">{course.min15}</span>
                </div>
              </Styled.Mods>
            </Accordion.Header>
            <Accordion.Body>
              <Styled.AimsBody>
                <div className="aimsLevel">
                  <Radio className="sum">{course.quiz}</Radio>
                  <Radio className="sum">{course.sum}</Radio>
                </div>
              </Styled.AimsBody>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <div className="examscheck" onClick={() => handleExamRoute()}>
              <Checkbox className="ex">Exam</Checkbox>
            </div>
          </Accordion.Item>
        </Accordion>
      </div>
    </Styled.MainModule>
  );
};

export default SideBarPage;
