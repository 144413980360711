import * as Styled from "./style";
import React from "react";
import avtavLogoTraining from "../../../assets/Svg/avtavLogoTraining.svg";
import navbg from "../../../assets/image/navbg.jpg";
import { paymentTick } from "./data";
import DropDownHeader from "../../DropDownHeader/DropDownHeader";

export const HeaderTraining = () => {
  return (
    <>
      <Styled.Background>
        <img src={navbg} width="100%" alt="navbg" />

        <Styled.Container>
          <Styled.BoxContainer>
            <Styled.ImgContainer>
                <img src={avtavLogoTraining} alt="logo" width="100%" />
            </Styled.ImgContainer>
            <Styled.IconMap>
              <div className="img">
                {paymentTick?.map((item, index) => {
                  return <img key={index} src={item?.img} />;
                })}
              </div>
            </Styled.IconMap>
          </Styled.BoxContainer>
          <Styled.Icons>
            <DropDownHeader trigger={"BA"} />
          </Styled.Icons>
        </Styled.Container>

      </Styled.Background>
    </>
  );
};
