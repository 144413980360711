import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { course, starRate } from "./data";

const RatingModal = ({ name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <a type="primary" onClick={showModal}>
        {name}
      </a>
      <Styled.ModalContainer
        style={{
          top: 20,
        }}
        width={936}
        footer={null}
        closeIcon={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Styled.ModalItems>
          <div className="modalrate">
            <div className="rate">{course.modalrating}</div>
          </div>
          <div className="courses">
            <div className="namecourse">{course.namecourse}</div>
            <div className="gsat">{course.gsat}</div>
          </div>
          <Styled.LineRating />
          <div className="stars">
            {starRate?.map((item, index) => {
              return <img key={index} src={item?.img} />;
            })}
          </div>
          <div className="textareas">
            <Styled.TextRating placeholder={course.comnt} />
          </div>
          <div className="submit">
            <Button title="Submit Rating" className="ratings" />
          </div>
        </Styled.ModalItems>
      </Styled.ModalContainer>
    </>
  );
};
export default RatingModal;
