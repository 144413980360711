import React from 'react'
import MainLayout from '../../layout/MainLayout'

export const VettingPage = () => {
  return (
    <MainLayout>
    <h1>Vetting</h1>
    </MainLayout>
  )
}
