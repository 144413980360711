import * as Styled from "./style";
import GeneralCourse from "./GeneralCourse";
import React from "react";
import SideBarPage from "../FirstScreen/SideBarPage";
import cancel_24px from "../../../assets/Svg/cancel_24px.svg";
import { RxSlash } from "react-icons/rx";
import { course } from "../FirstScreen/data";
import { HeaderTraining } from "../Header/HeaderTraining";
import { useLocation } from "react-router-dom";

const ExamCourse = () => {
  const location = useLocation();
  return (
    <>
      <HeaderTraining />
      <Styled.Module>
        <Styled.Training>
          <div className="secure">
            <div className="cor">
              {course.cor}
              <RxSlash className="slash" />
              {location?.state?.title}
            </div>
          </div>
          <div className="firstScreen">
            <GeneralCourse data={location} />
          </div>
        </Styled.Training>
        <Styled.TickData>
          <div>
            <div>
              <div className="maincourse">
                <div className="mod">
                  {course.mod}
                  <br />
                  <span className="mainModule">{course.moduleno}</span>
                </div>
                <div>
                  <img src={cancel_24px} className="cancel" />
                </div>
              </div>
            </div>
            <div>
              <SideBarPage />
            </div>
          </div>
        </Styled.TickData>
      </Styled.Module>
    </>
  );
};

export default ExamCourse;
