import styled from "styled-components";
import { color } from "../../../theme/Color";

export const Table = styled.div`
  .ant-table-wrapper .ant-table-thead {
    color: ${color._949494};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const MainContainer = styled.div`
  margin: 5px 0px 45px 0px;
  border-radius: 5px;
  border: 0.7px solid ${color._ededde};
  background: ${color._ffffff};
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
`;
export const Invoice = styled.div`
  display: flex;
  gap: 9px;
  padding-bottom: 18px;
  .invoiceName {
    color: ${color._0f2a3c};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .number {
    color: ${color._6091a8};
    font-family: Open Sans;
    margin-top: 1px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const BelowText = styled.div`
  padding-bottom: 34px;
  color: ${color._68828e};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const Searchbar = styled.div`
  .ant-input-affix-wrapper .ant-input-prefix {
    width: 12px;
    margin-top: 1px;
  }
  .searchicon {
    margin-top: 5px;
  }
  .ant-input-affix-wrapper-lg {
    padding: 0px;
  }
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 234px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  .search {
    border: none;
    outline: none;
    padding: 10px;
  }
  .search {
    gap: 9px;
  }

  ::placeholder {
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const TableHeader = styled.div`
  padding: 13px 20px 13px 17px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  padding-bottom: 14px;
  .tablecontainer {
    display: flex;
    gap: 12px;
  }
`;
export const Dropdown = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 186px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;
export const DropData = styled.div`
  .gsat {
    border: none;
    padding-right: 92px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Filter = styled.div`
  padding: 10px 0px;
  display: flex;
  width: 210px;
  gap: 5px;
  height: 36px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  padding: 0px 8px;

  .filter {
    font-size: 12px;
  }
`;
export const NewestFirst = styled.div`
  .newest {
    border: none;
    padding-right: 82px;
    color: ${color._000};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
  }
`;
export const DotIcon = styled.div`
  width: 38px;
  height: 36px;
  border-radius: 5px;
  border: 1px solid ${color._ededee};
  background: ${color._ffffff};
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Pipe = styled.div`
  display: flex;
  background-color: ${color._6091a8};
  color: ${color._6091a8};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 2px;
  height: 20px;
  margin-top: 4px;
`;
