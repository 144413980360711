import styled from "styled-components";
import { Modal } from "antd";
import { color } from "../../../../theme/Color";

export const ModalBox = styled.div`
  height: 286px;
  flex-shrink: 0;
  border-radius: 2px;
  background: ${color._0f2a3c};
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
`;

export const ModalContainer = styled(Modal)`
  & .ant-modal-content {
    padding: 32px 45px 50px 45px;
  }
  &.ant-modal {
    max-width: calc(100% - 32px);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.div`
  width: 38px;
  height: 38px;
  background: rgba(239, 243, 249, 0.38);
  border-radius: 28px;
  cursor: pointer;
  .closeIcon {
    height: 24px;
    width: 24px;
    color: rgba(109, 125, 139, 1);
    margin-top: 7px;
    margin-left: 7px;
  }
`;

export const ModalTitle = styled.div`
  color: ${color._16384e};
  font-family: Raleway;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 25px;
`;

export const Line = styled.div`
  height: 0.4px;
  background: ${color._DADADA};
`;

export const CourseName = styled.div`
  padding: 25px 43px;
  .english {
    display: flex;
    align-items: center;
    gap: 5px;
    color: ${color._ffffff};
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .gsat {
    color: ${color._ffffff};
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .head {
    color: ${color._5193aa};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .general {
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${color._ffffff};
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const Receipt = styled.div`
  padding: 25px 43px;
  display: flex;
  justify-content: space-between;

  .recName {
    color: ${color._5193aa};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .staceyM {
    color: ${color._ffffff};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
export const Preview = styled.div`
  padding: 25px 43px;
  display: flex;
  justify-content: space-between;
  .certify {
    color: ${color._ffbe32};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    display: flex;
    gap: 0.4rem;
  }
  .download {
    color: ${color._ffffff};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .downloadIcon {
    height: 24px;
    width: 24px;
    color: ${color._5193aa};
  }
`;
export const Training = styled.div`
  margin-top: 49px;
  .expire {
    color: ${color._0f2a3c};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .valid {
    color: ${color._0f2a3c};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
