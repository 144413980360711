import authReducer from "./slices/authSlice";
import billingReducer from "./slices/billingSlice";
import cartReducer from "./slices/cartSlice";
import checkoutReducer from "./slices/checkoutSlice";
import commonReducer from "./slices/commonSlice";
import coursesReducer from "./slices/coursesSlice";
import declarationReducer from "./slices/declarationSlice";
import invitedReducer from "./slices/invitedSlice";
import invoicePaymentReducer from "./slices/invoicePaymentSlice";
import licenseReducer from "./slices/licensesSlice";
import myCourseReducer from "./slices/myCourseSlice";
import preCourseQuestionReducer from "./slices/preCourseQuestionSlice";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import uplaodCSVReducer from "./slices/uploadCSVSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import OrderCancelForm from "./slices/orderCancelFormSlice";
import corporateUserReducer from "./slices/corporateUserSlice";
import feedBackReducer from "./slices/feedBackSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  authReducer,
  coursesReducer,
  cartReducer,
  commonReducer,
  checkoutReducer,
  declarationReducer,
  licenseReducer,
  uplaodCSVReducer,
  invitedReducer,
  billingReducer,
  invoicePaymentReducer,
  myCourseReducer,
  preCourseQuestionReducer,
  OrderCancelForm,
  corporateUserReducer,
  feedBackReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store);
