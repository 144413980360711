import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import Button from "../../../components/Atoms/Button/button";
import Card from "../../../components/Atoms/Cards/Card";
import React, { useEffect, useState } from "react";
import UploadviaCSVModal from "./UploadCSV";
import trainingLogo from "../../../assets/Svg/trainingLogo.svg";
import useCommonHook from "../../../hooks/CommonHook";
import useNewLicensesHook from "../../../hooks/AddNewLicensesHook";
import { Tabs } from "antd";
import { AiOutlineSearch } from "react-icons/ai";
import { BiFilterAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GrFormAdd } from "react-icons/gr";
import { IoMdCall, IoMdMail } from "react-icons/io";
import { IoIosSend } from "react-icons/io";
import { MdAdd, MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLicense } from "../../../hooks/LicenseHook";
import { trainingConstant } from "../../TrainingCourses/data";
import { License, ModalText } from "../businessConstant";
import { AddtraineeForm } from "./AddTraineeModal";
import { buttonName } from "./LicenseData";
import { message } from "antd";

const BusinessUserLicense = () => {
  const [errors, setErrors] = useState([]);
  const { handleData } = useNewLicensesHook();
  const {
    licenseTableData,
    TableHeader,
    licenseCourseDetails,
    fetchLicensesList,
    loading,
  } = useLicense();
  const [currentTab, setCurrentTab] = useState({ label: "Add Users" });
  const [issueLicenseOpen, setIssueLicenseOpen] = useState(false);
  const [isAddButtonClicked, setIsAddButtonClicked] = useState(true);
  const [declarationStatus, setDeclarationStatus] = useState();
  const loggedInUser = useSelector((state) => state.authReducer.user);

  const [uplaodViaCSV, setUplaodViaCSV] = useState({
    invitedUsers: "",
    courseId: "",
    is5YearTrainee: false,
    isAirsideJob: false,
    orderId: "",
  });

  const [formData, setFormData] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      role: "",
      courseId: "",
      is5YearTrainee: false,
      isAirsideJob: false,
      orderId: "",
      sign: "",
    },
  ]);

  const handleChange = (index) => (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const newFomData = [...formData];
    const newErrors = [...errors];

    newFomData[index] = { ...newFomData[index], [name]: value };
    if (name === "is5YearTrainee" || name === "isAirsideJob") {
      newFomData[index] = {
        ...newFomData[index],
        [name]: event.target.checked,
      };
    } else {
      newFomData[index] = { ...newFomData[index], [name]: value };
    }

    newErrors[index] = { ...newErrors[index], [name]: undefined };

    if (name == "courseId") {
      const selectedCourses = licenseTableData?.find((course) => {
        return course.courseId == event.target.value;
      });
      const selectedCourse = selectedCourses ? selectedCourses : null;
      setDeclarationStatus(selectedCourse);

      if (selectedCourse) {
        newFomData[index] = {
          ...newFomData[index],
          courseId: selectedCourse.courseId,
          orderId: selectedCourse.orderId,
          courseType: selectedCourse.course.type,
          sign: selectedCourse.sign,
        };
      }
    }
    setFormData(newFomData);
    setErrors(newErrors);
  };

  const validateField = (key, value, item) => {
    if (key === "sign") {
      return null;
    }
    if (typeof value === "string" && !value.trim()) {
      return "Field cannot be empty";
    } else if (key === "courseId") {
      if (!item?.courseId) {
        return "Please select a course before submitting";
      }

      if (!item.courseId && Object.keys(errors).length === 0) {
        return "Please select a course before submitting";
      }

      if (
        item.courseId &&
        !(item?.is5YearTrainee && item?.isAirsideJob) &&
        item?.courseType === "AVSEC"
      ) {
        message.error("Please select both checkboxes before submitting");
      }
    } else if (key === "email") {
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
      if (!emailRegex.test(value)) {
        return "Invalid email format";
      }
    } else if (key === "contactNumber") {
      const numberRegex = /^\d{10,15}$/;
      if (!numberRegex.test(value)) {
        return "Invalid number format";
      }
    }

    return null;
  };

  const validateItem = (item) => {
    const errors = {};

    Object.keys(item).forEach((key) => {
      const value = item[key];
      const error = validateField(key, value, item);

      if (error !== null) {
        errors[key] = error;
      }
    });

    return errors;
  };

  const signature = localStorage.getItem("sign");

  const handleSubmit = async () => {
    const newFieldErrors = [];
    const newFormDataToSend = [];
    let hasCourseError = false;

    formData?.forEach((item, index) => {
      const fieldErrors = validateItem(item);
      const { courseType, ...formDataWithoutCourseType } = item;

      newFieldErrors[index] = fieldErrors;
      newFormDataToSend.push({ ...formDataWithoutCourseType });

      if (fieldErrors.courseId) {
        hasCourseError = true;
      }
    });

    setErrors(newFieldErrors);
    if (declarationStatus?.status === 0 || declarationStatus?.status === 2) {
      message.error(
        "The declaration form has either been denied or is awaiting approval"
      );
    } else if (
      !licenseTableData[0]?.staffDetails?.sign &&
      signature === null &&
      (loggedInUser?.type === "Operations" ||
        loggedInUser?.type === "Finance Account")
    ) {
      message.error("Signature is required");
    } else if (
      !hasCourseError &&
      newFieldErrors.every((errors) => Object.keys(errors).length === 0)
    ) {
      await handleData(newFormDataToSend);

      setFormData([
        {
          firstName: "",
          lastName: "",
          email: "",
          contactNumber: "",
          role: "",
          courseId: "",
          is5YearTrainee: false,
          isAirsideJob: false,
          orderId: "",
          sign: "",
        },
      ]);
      setIsAddButtonClicked(true);
      setIssueLicenseOpen(false);
    } else {
      message.error("Please check all the form field and try again.");
    }
  };

  const handleButtonClick = () => {
    const _formData = [...formData];
    _formData.push({
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      role: "",
      courseId: "",
      is5YearTrainee: false,
      isAirsideJob: false,
      orderId: "",
      sign: "",
    });
    setFormData(_formData);
    setIsAddButtonClicked(false);
  };

  const handleDelete = (index) => {
    if (formData.length > 1) {
      const newFormData = [...formData];
      newFormData.splice(index, 1);
      setFormData(newFormData);

      if (newFormData.length === 1) {
        setIsAddButtonClicked(true);
      }
    }
  };

  const licenseRelatedCourses = useSelector(
    (state) => state?.licenseReducer?.licenseList?.relatedCourses
  );
  const licenseCount = useSelector(
    (state) => state?.licenseReducer?.licenseList?.count
  );

  const [tableParams, setTableParams] = useState({
    pagination: {
      total: licenseCount,
      current: 1,
      pageSize: 5,
    },
  });
  useEffect(() => {
    setTableParams({
      pagination: {
        total: licenseCount,
        current: 1,
        pageSize: 5,
      },
    });
  }, [licenseCount]);
  const onTabChange = (key) => {
    setCurrentTab(items?.find((item) => item.key === key));
  };

  const items = [
    {
      key: 1,
      label: "Add Users",
      children: <div></div>,
    },
    {
      key: 2,
      label: ModalText.UploadviaCSV,
      children:
        currentTab?.label === ModalText.UploadviaCSV ? (
          <UploadviaCSVModal
            uplaodViaCSV={uplaodViaCSV}
            setUplaodViaCSV={setUplaodViaCSV}
            setIssueLicenseOpen={setIssueLicenseOpen}
          />
        ) : null,
    },
  ];
  const [search, setSearch] = useState({
    page: 1,
    limit: 5,
    title: "",
    type: "",
  });

  const handleOk = () => {
    setIssueLicenseOpen(false);
  };

  const handleCancel = () => {
    setCurrentTab({ label: "Add Users" });

    setUplaodViaCSV({
      invitedUsers: "",
      courseId: "",
      is5YearTrainee: false,
      isAirsideJob: false,
      orderId: "",
    });
    setIssueLicenseOpen(false);
    setIsAddButtonClicked(true);
    setFormData([
      {
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        role: "",
        courseId: "",
        is5YearTrainee: false,
        isAirsideJob: false,
        orderId: "",
      },
    ]);
    setErrors([]);
  };

  const { useDebounce, fetchLicenseTypeList } = useCommonHook();
  const debouncedSearch = useDebounce(search, 500);
  useEffect(() => {
    fetchLicensesList({
      ...debouncedSearch,
      page: tableParams.pagination.current,
      type: search?.type,
    });
  }, [debouncedSearch, tableParams]);

  const showIssueModal = () => {
    setIssueLicenseOpen(true);
    fetchLicensesList();
  };

  const typeList = useSelector((state) => state.commonReducer.coursesTypeList);

  useEffect(() => {
    fetchLicenseTypeList();
  }, []);

  const handleSearch = (e) => {
    setSearch({ ...search, title: e.target.value });
  };
  const handleType = (e) => {
    setSearch({ ...search, type: e.target.value });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <>
      <Styled.Container>
        <Styled.Heading>
          <div>{License.course}</div>
          <Styled.Pipe />
          <span className="number">{licenseCount}</span>
        </Styled.Heading>

        <Styled.IssueButton>
          <Button
            btn={true}
            icon={<MdAdd size={14} />}
            title={License.issue}
            className="issue"
            type="primary"
            onClick={() => showIssueModal(true)}
          ></Button>
          {issueLicenseOpen ? (
            <Styled.ModalContainer
              style={{
                top: 20,
              }}
              footer={false}
              open={issueLicenseOpen}
              width={950}
              closeIcon={false}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <Styled.InviteContainer>
                <Styled.ModalHeader>
                  <Styled.Enroll>{ModalText.Enroll}</Styled.Enroll>
                  <Styled.Icon onClick={() => handleCancel(false)}>
                    <MdClose className="closeIcon" />
                  </Styled.Icon>
                </Styled.ModalHeader>

                <Styled.LicenseData className="licenseData">
                  <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={onTabChange}
                  />

                  {currentTab?.label === "Add Users" ? (
                    <>
                      <Styled.ADDButton>
                        {formData?.map((formItem, index) => {
                          return (
                            <AddtraineeForm
                              handleChange={handleChange}
                              handleSubmit={handleSubmit}
                              errors={errors}
                              setErrors={setErrors}
                              handleData={handleData}
                              formData={formItem}
                              setFormData={setFormData}
                              index={index}
                              isAddButtonClicked={isAddButtonClicked}
                              handleDelete={handleDelete}
                              declarationStatus={declarationStatus}
                              fetchLicensesList={fetchLicensesList}
                            />
                          );
                        })}

                        <Button
                          btn={true}
                          className="addTrainee"
                          icon={<GrFormAdd className="addIcon" />}
                          title={buttonName.Trainee}
                          onClick={() => handleButtonClick()}
                        ></Button>
                      </Styled.ADDButton>
                      <Styled.Addmoreusers className="addmore">
                        {ModalText.AddMoreUsers}
                      </Styled.Addmoreusers>
                      <Styled.FooterButton>
                        <Button
                          className="invitebutton"
                          title={buttonName.Users}
                          icon={<IoIosSend className="icon" />}
                          onClick={handleSubmit}
                        />
                      </Styled.FooterButton>
                    </>
                  ) : (
                    <></>
                  )}
                </Styled.LicenseData>
              </Styled.InviteContainer>
            </Styled.ModalContainer>
          ) : null}
        </Styled.IssueButton>
      </Styled.Container>
      <Styled.TableData>
        <Styled.SearchGSATContainer>
          <Styled.SearchContainer>
            <AiOutlineSearch className="searchIcon" size={12} />
            <Styled.Input
              type="text"
              placeholder="Search"
              value={search?.title}
              onChange={handleSearch}
            />
          </Styled.SearchContainer>
          <Styled.IconGsat>
            <Styled.DropdownContent className="gsat">
              <BiFilterAlt className="filter" />
              <Styled.DropData>
                <select
                  onChange={handleType}
                  name="type"
                  id="type"
                  value={search?.type}
                  className="placeholderValue"
                >
                  <option value="" selected>
                    {ModalText.SelectcourseType}
                  </option>
                  {typeList?.map((item) => {
                    return (
                      <option value={item?.name} selected>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
              </Styled.DropData>
            </Styled.DropdownContent>
            <Styled.DotIcon>
              <BsThreeDotsVertical className="dots" />
            </Styled.DotIcon>
          </Styled.IconGsat>
        </Styled.SearchGSATContainer>

        <AntdTable
          data={licenseCourseDetails}
          columns={TableHeader}
          numberOfTableData={tableParams.pagination.pageSize}
          onChange={handleTableChange}
          {...tableParams}
          loading={loading}
        />
      </Styled.TableData>
      <Styled.DifferentContainers>
        <div>
          <Styled.RegulateHeading>
            <Styled.TitleCourse>
              {License.relate}
              <Styled.Border />
            </Styled.TitleCourse>
          </Styled.RegulateHeading>
          <Styled.RegulatedCourseHeader>
            <img src={trainingLogo} alt="logo" className="training" />
            <Styled.TitleLine>
              <Styled.Title>{trainingConstant.Regulated}</Styled.Title>
            </Styled.TitleLine>
          </Styled.RegulatedCourseHeader>

          <Styled.RegulateMap>
            <Styled.Items>
              {licenseRelatedCourses?.map((items, index) => {
                return (
                  <Card
                    data={items}
                    children={
                      <>
                        <Styled.NumberBlock>
                          <Styled.CardNumber>
                            <IoMdCall className="call" /> {items?.contactMobile}
                          </Styled.CardNumber>
                          <Styled.Lines />
                          <Styled.PhoneNumber>
                            {items?.contactPhone}
                          </Styled.PhoneNumber>
                        </Styled.NumberBlock>
                        <Styled.Email>
                          <IoMdMail className="mail" /> {items?.contactEmail}
                        </Styled.Email>

                        <Styled.CardButton>
                          <Button
                            title={"Browse Course Information"}
                            className={"btn"}
                          />
                        </Styled.CardButton>
                      </>
                    }
                  />
                );
              })}
            </Styled.Items>
          </Styled.RegulateMap>
        </div>
      </Styled.DifferentContainers>
    </>
  );
};

export default BusinessUserLicense;
