import { Progress } from "antd";
import {
  TableDataColor,
  TagRender,
  BuyMoreText,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";
import { useState } from "react";
import HttpApis from "../services/HttpApis";
import { licenseListSuccess } from "../redux/slices/licensesSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Routes } from "../routes/routes";

export const useLicense = () => {
  const [loading,setLoading]=useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const licenseTableData = useSelector(
    (state) => state?.licenseReducer?.licenseList?.licenses
  );

  const handleBuyMore = (row) => {
    navigate(`${Routes?.multiUserDetailPage}/${row.slug}`);
  }

  const fetchLicensesList = async (params) => {
    setLoading(true)
    try {
      const response = await HttpApis.getCorporateLicenses(params);
      dispatch(licenseListSuccess(response.data));
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  
  const TableHeader = [
    {
      label: `Course Name`,
      mappedkey: "courseName",
      sorter: (a, b) => a.courseName.localeCompare(b.courseName),
      customRender: TableDataColor,
    },
    {
      label: "Purchased Licenses",
      mappedkey: "purchased",
      customRender: TableDataColor,
    },
    {
      label: "Active ",
      mappedkey: "active",
      customRender: TagRender,
    },
    {
      label: "Completed",
      mappedkey: "completed",
      customRender: TagRender,
    },
    {
      label: "Remaining",
      mappedkey: "remaining",
      customRender: TagRender,
    },
    {
      label: "Action",
      mappedkey: "buy",
      customRender: BuyMoreText,
      action: handleBuyMore
    },
  ];

  const HeaderData = [
    {
      label: "Date Added",
      mappedkey: "dateAdded",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: TableDataColor,
    },
    {
      label: "Course Name",
      mappedkey: "courseName",
    },
    {
      label: "Trainee Name",
      mappedkey: "traineeName",
    },
    {
      label: "Email",
      mappedkey: "email",
    },
    {
      label: "Course Progress",
      mappedkey: "courseProgress",
    },
    {
      label: "Action",
      mappedkey: "actionData",
    },
  ];

  const licenseCourseDetails = licenseTableData?.map((item) => ({
    courseName: item?.course?.title,
    purchased: item?.qty,
    active: item?.active,
    completed: item?.complited,
    remaining: item?.remaining,
    slug: item?.course.slug,
    type: item?.course.type,
    buy: "Buy more",
  }));

  return {
    TableHeader,
    HeaderData,
    licenseCourseDetails,
    licenseTableData,
    fetchLicensesList,
    loading,
  };
};
