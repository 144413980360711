import * as Styled from "./style";
import AntdTable from "../../../components/Atoms/Table";
import React, { useState } from "react";
import { Input } from "antd";
import { BiFilterAlt } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgSearch } from "react-icons/cg";
import { FaRegFileExcel } from "react-icons/fa";
import { LuListFilter } from "react-icons/lu";
import { dataItem } from "./data";
import { useCompletedCourse } from "../../../hooks/CompletedCourseHook";

export const CompletedCourses = () => {
  const {completedCourse, completedCourseData} = useCompletedCourse()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="container">
      <Styled.MainContainer>
        <Styled.TableHeader className="container">
          <div className="tablecontainer">
            <Styled.Searchbar>
              <Input
                className="search"
                size="large"
                placeholder={dataItem.trainee}
                prefix={<CgSearch className="searchicon" />}
              />
            </Styled.Searchbar>
            <Styled.Dropdown>
              <BiFilterAlt className="filter" />
              <Styled.DropData>
                <select name="language" id="language" className="gsat">
                  <option value="GSAT" selected>
                    {dataItem.GSAT}
                  </option>
                </select>
              </Styled.DropData>
            </Styled.Dropdown>

            <Styled.Filter>
              <LuListFilter className="filter" />
              <Styled.NewestFirst>
                <select name="language" id="language" className="newest">
                  <option value="GSAT" selected>
                    {dataItem.Newestfirst}
                  </option>
                </select>
              </Styled.NewestFirst>
            </Styled.Filter>
            <Styled.Spreadsheet>
              <FaRegFileExcel />
              <span>{dataItem.DownloadSpreadsheet}</span>
            </Styled.Spreadsheet>
          </div>

          <Styled.DotIcon>
            <BsThreeDotsVertical className="dots" />
          </Styled.DotIcon>
        </Styled.TableHeader>
        <div>
          <AntdTable data={completedCourseData} columns={completedCourse} rowSelection={rowSelection}/>
        </div>
      </Styled.MainContainer>
    </div>
  );
};
