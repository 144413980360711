import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SideBarPage from "./SideBarPage";
import TrainingAwareness from "./TrainingAwareness";
import cancel_24px from "../../../assets/Svg/cancel_24px.svg";
import { FaAngleDown } from "react-icons/fa";
import { RxSlash } from "react-icons/rx";
import { HeaderTraining } from "../Header/HeaderTraining";
import { course } from "./data";

const itemRender = (_, type, originalElement) => {
  if (type === "prev") {
    return <a>Previous</a>;
  }
  if (type === "next") {
    return <a>Next</a>;
  }
  return originalElement;
};
const CoursesModule = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  };
  useOnClickOutside(
    ref,
    useCallback(() => setOpen(false), [])
  );
  return (
    <>
      <HeaderTraining />
      <Styled.Module>
        <Styled.Training>
          <div className="secure">
            <div className="cor">
              {course.cor}

              <RxSlash className="slash" />
              {course.generals}
            </div>
            <div className="progressBar">
              <Button title={course.rev} className="rev" />
              <div>
                <div className="complete">
                  <div> 0% Complete</div>
                  <Styled.DownIcon ref={ref}>
                    <FaAngleDown
                      className="faangle"
                      onClick={() => setOpen(!open)}
                    />
                    {open && (
                      <Styled.DropMenu>
                        <div className="stepsof">{course.stepsof}</div>
                        <Styled.Lined />
                        <div className="certify">{course.coursecertified}</div>
                      </Styled.DropMenu>
                    )}
                  </Styled.DownIcon>
                </div>
                <Styled.ProgressMain
                  percent={30}
                  showInfo={false}
                  size="1051"
                  strokeColor="rgba(4, 136, 182, 1)"
                  trailColor="white"
                />
              </div>
            </div>
          </div>
          <div>
            <video width="100%" height="487" controls>
              <source src="abc.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="firstScreen">
            <TrainingAwareness />
          </div>
        </Styled.Training>
        <Styled.TickData>
          <div>
            <div>
              <div className="maincourse">
                <div className="mod">
                  {" "}
                  {course.mod}
                  <br />
                  <span className="mainModule">{course.moduleno}</span>
                </div>
                <div>
                  <img src={cancel_24px} className="cancel" />
                </div>
              </div>
            </div>
            <Styled.Aims>
              <SideBarPage />
            </Styled.Aims>
          </div>
        </Styled.TickData>
      </Styled.Module>
      <Styled.Page>
        <Styled.Pagin
          total={50}
          itemRender={itemRender}
          showQuickJumper={false}
          showSizeChanger={false}
          colorTextPlaceholder="red"
        />
      </Styled.Page>
      
    </>
  );
};

export default CoursesModule;
