import * as Styled from "./style";
import Button from "../../../components/Atoms/Button/button";
import React, { useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useCourse } from "../../../hooks/CourseHook";
import { CompletedCourses } from "../BusinessUserCompletedCourses/CompletedCourses";
import { buttonData, dataItem } from "./ButtonData";

const CoursesDetail = () => {
  const [showComponent, setShowComponent] = useState(<CompletedCourses />);
  const [isActive, setIsActive] = useState(0);
  const { setShow } = useCourse();

  const handleClick = (item, index) => {
    setShowComponent(item.child);
    setIsActive(index);
  };
  return (
    <>
      <Styled.Container>
        <Styled.BackContainer>
          <FaLongArrowAltLeft className="icon" onClick={() => setShow(false)} />
          <Styled.Back>{dataItem.Back}</Styled.Back>
        </Styled.BackContainer>
        <Styled.CourseName>{dataItem.CourseName}</Styled.CourseName>
        <Styled.Line />
        <Styled.Main>
          {buttonData?.map((item, index) => {
            return (
              <Styled.Wrapper>
                <Button
                  key={index}
                  title={item?.title}
                  className={item?.id === isActive ? "active" : "nonActive"}
                  onClick={() => handleClick(item, index)}
                />
              </Styled.Wrapper>
            );
          })}
        </Styled.Main>
      </Styled.Container>
      <Styled.Component>{showComponent}</Styled.Component>
    </>
  );
};

export default CoursesDetail;
