import CertificateModal from "../containers/SingleUserProfile/Certificate/CertificateModal";
import CompletedCourseModal from "./../containers/Corporate/BusinessUserCompletedCourses/BusinessCompletedCourseModal/CompletedCourseModal";
import { MdDownload } from "react-icons/md";

import {
  Courses,
  InvoiceDownload,
  InvoiceDownloadDisable,
} from "../containers/Corporate/BusinessUserLicense/CustomRender";


export const useIndividualCertificatesHook = () => {
  const TableCourse = [
    {
      label: "Completion Date",
      mappedkey: "completionDate",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: Courses,
    },
    {
      label: "Expiry Date",
      mappedkey: "expiryDate",

      customRender: Courses,
    },
    {
      label: "Trainee Name",
      mappedkey: "traineeName",
      customRender: Courses,
    },
    {
      label: "Course Name ",
      mappedkey: "courseName",
      customRender: Courses,
    },
    {
      label: "Purchasing Account",
      mappedkey: "PurchasingAccount",
    },

    {
      label: "Certification",
      mappedkey: "Certificate",
      customRender: Courses,
    },
    {
      label: "Pass/Fail",
      mappedkey: "passFail",
    },
    {
      label: "Download",
      mappedkey: "Download",
      customRender: InvoiceDownload,
    },
  ];

  const tableData = [
    {
      completionDate: "25/03/2022",
      expiryDate: "25/03/2022",
      traineeName: "Stacey Matthews",
      courseName: "General Awareness Training Awareness",
      PurchasingAccount: "Self Purchased",

      Certificate: <CompletedCourseModal name={"Preview"} />,
      passFail: "93% - Pass",
      Download: <MdDownload />,
    },
  ];
  const CorporateTableCourse = [
    {
      label: "Completion Date",
      mappedkey: "completionDate",
      defaultSortOrder: "descend",
      sorter: true,
      customRender: Courses,
    },
    {
      label: "Expiry Date",
      mappedkey: "expiryDate",

      customRender: Courses,
    },
    {
      label: "Trainee Name",
      mappedkey: "traineeName",
      customRender: Courses,
    },
    {
      label: "Course Name ",
      mappedkey: "courseName",
      customRender: Courses,
    },
    {
      label: "Purchasing Account",
      mappedkey: "PurchasingAccount",
    },
    {
      label: "Pass/Fail",
      mappedkey: "passFail",
    },
    {
      label: "Certification",
      mappedkey: "Certificate",
      customRender: Courses,
    },
    {
      label: "Download",
      mappedkey: "Download",
      customRender: InvoiceDownloadDisable,
    },
  ];
  const CorporatetableData = [
    {
      completionDate: "25/03/2022",
      expiryDate: "25/03/2022",
      traineeName: "Stacey Matthews",
      courseName: "CPC Periodic Training",
      PurchasingAccount: "Virgin Airlines",
      passFail: "50% - Pass",
      Certificate: <CertificateModal name={"Preview"} />,

      Download: <MdDownload />,
    },
    {
      completionDate: "20/01/2022",
      expiryDate: "20/01/2022",
      traineeName: "Stacey Matthews",
      courseName: "General Awareness Training Awareness",
      PurchasingAccount: "British Airways",
      passFail: "50% - Pass",
      Certificate: <CertificateModal name={"Preview"} />,
      Download: <MdDownload />,
    },
  ];
  return { TableCourse, tableData, CorporateTableCourse, CorporatetableData };
};
